<template>
    <transition name="modal-fade">
        <div class="modal-backdrop z-10 overflow-auto" tabindex="0" @keydown.esc="close">
            <div class="set-background bg-white max-h-full">
                <header class="p-3 flex justify-between">
                    <slot name="header" class="font-serif" />
                    <button type="button" class="pr-1" @click="close">
                        <i class="fa-light fa-xmark" />
                    </button>
                </header>
                <section class="p-4">
                    <slot name="body">
                        I'm the default body!
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer" />
                </footer>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "modal",

    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style>
.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s ease;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.set-background {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal-background {
    background: #ffffff;
}

.modal-header,
.modal-footer {
    padding: 10px;
    display: flex;
}

.modal-header {
    color: #4aae9b;
    justify-content: space-between;
}

.modal-footer {
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}
</style>
